<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('assignments.assignmentsArchive') }}
        <small>
          <b-link v-on:click="navigateToEntityPage()">({{ entityData.name }})</b-link>
        </small>
      </template>
    </RMPageHeader>
    <div v-if="loadingAssignments" class="row m-t-20">
      <div class="col-12">
        <b-skeleton class="height-200"></b-skeleton>
      </div>
    </div>
    <div v-else-if="this.formattedAssignments.length === 0" class="row m-t-20">
      <div class="col-12">
        <b-alert show variant="light" class="text-center" style="font-size: medium">
          {{ $t("assignments.noArchivedAssignments") }}
        </b-alert>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-xl-4 col-xxl-3 col-xxxl-3"
        v-for="(myA, idx) in formattedAssignments"
        :key="idx"
      >
        <RMAssignmentPanel :assignment="myA" :entity="entity" :entity-id="entityId"/>
      </div>
    </div>
  </div>
</template>

<script>
import RMPageHeader from "@/components/common/RMPageHeader.vue";
import {isTimeInProgress, isTimeOverdue, isTimeUpcoming} from "@/_helpers/assignments_helper";
import {assignmentService} from "@/_services/assignment.service";
import RMAssignmentPanel from "@/components/assignments/RMAssignmentPanel.vue";
import {mentorshipService} from "@/_services/mentorship.service";
import {getEntityFromPath, getEntityIdFromPath, navigateToEntityPage} from "@/_helpers/vue.helper";

export default {
  name: "AssignmentsArchive",
  components: {RMAssignmentPanel, RMPageHeader},
  data() {
    return {
      loadingAssignments: false,
      assignments: [],
      entityData: {},
      entityLoading: false,
    }
  },
  computed: {
    formattedAssignments() {
      const activeAssignments = this.assignments.filter(x => isTimeInProgress(x));
      const upcomingAssignments = this.assignments.filter(x => isTimeUpcoming(x));
      const overdueAssignments = this.assignments.filter(x => isTimeOverdue(x));
      return [...activeAssignments, ...upcomingAssignments, ...overdueAssignments];
    },
    entity() {
      return getEntityFromPath(this);
    },
    entityId() {
      return getEntityIdFromPath(this);
    },
  },
  methods: {
    navigateToEntityPage() {
      return navigateToEntityPage(this);
    },
    loadAssignments() {
      this.loadingAssignments = true;

      assignmentService.listAssignments(this.entity, this.entityId,"ARCHIVED").then((data) => {
        this.assignments = data.assignments;
      }).finally(() => {
        this.loadingAssignments = false;
      })
    },
    loadStudentGroup() {
      this.entityLoading = true;

      mentorshipService.getStudentGroup(this.entityId).then((data) => {
        this.entityData = data.data;
        this.entityLoading = false;
      }).catch((error) => {
        this.error = error;
      })
    },
    loadMyStudent() {
      this.entityLoading = true;

      mentorshipService.getMyStudent(this.entityId).then((data) => {
        this.entityData = data.data;
        this.entityLoading = false;
      }).catch((error) => {
        this.error = error;
      })
    },
  },
  created() {
    this.loadAssignments();
    if(this.entity === "studentGroups"){
      this.loadStudentGroup();
    }
    if(this.entity === "myStudents"){
      this.loadMyStudent();
    }
  }
}
</script>